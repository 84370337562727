import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import {useState} from 'react';

interface TextInputDialogProps {
  show: boolean;
  onChanged?: (value: string) => void;
  onCancelled?: () => void;
  title: string;
  message: string;
  initialValue?: string;
  error?: string;
  okText?: string;
  loading?: boolean;
  autoComplete?: string;
  placeholder?: string;
  dialogProps?: Omit<DialogProps, 'open'>;
  inputProps?: TextFieldProps;
}

export const TextInputDialog = ({
  show,
  onChanged,
  onCancelled,
  message,
  title,
  initialValue,
  error,
  okText,
  loading,
  autoComplete,
  placeholder,
  dialogProps,
  inputProps,
}: TextInputDialogProps) => {
  const [value, setValue] = useState<string>(initialValue || '');

  const updateValue = (e: any) => {
    setValue(e.target.value);
  };

  const updateName = () => {
    if (onChanged) {
      onChanged(value);
    }
  };

  const cancelUpdate = () => {
    if (onCancelled) {
      onCancelled();
    }
  };

  return (
    <Dialog open={show} aria-labelledby="name-dialog-title" {...dialogProps}>
      <DialogTitle id="name-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        <TextField
          variant={'outlined'}
          placeholder={placeholder}
          required={true}
          fullWidth={true}
          autoComplete={autoComplete}
          autoFocus={true}
          error={!!error}
          helperText={error}
          value={value}
          onChange={updateValue}
          {...inputProps}
        />
      </DialogContent>
      <DialogActions>
        {onCancelled && (
          <Button onClick={cancelUpdate} color="primary">
            Cancel
          </Button>
        )}
        <Button onClick={updateName} color="primary" disabled={value.length === 0 || loading}>
          {okText || 'Continue'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
