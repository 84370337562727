export const Config = {

  apiMethod: 'appengine',

  api: {
    pageSize: 25,

    timeout: 150000,
    base: 'https://event-stream-271610.ew.r.appspot.com',
  },

  limits: {
    fileSize: 6 * 1024 * 1024, // 6 MB
  },
};
