import {Typography} from '@material-ui/core';
import firebase from 'firebase/app';
import {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import {getOwnPhotos} from '../../api/photos';
import {BreadcrumbHeader} from '../../components/BreadcrumbHeader';
import {PhotoCard} from '../../components/PhotoCard';
import {useUser} from '../../context/user';
import {Photo} from '../../types/gallery';

interface Props {
  galleryId: string;
}

export const OwnPhotos = (props: Props) => {
  const history = useHistory();
  const params = useParams<{code: string}>();
  const [photos, setPhotos] = useState<firebase.firestore.QueryDocumentSnapshot<Photo>[]>([]);
  const {userId} = useUser();

  useEffect(() => {
    if (props.galleryId && userId) {
      getOwnPhotos(props.galleryId, userId).then((ourPhotos) => {
        setPhotos(ourPhotos.docs);
      });
    }
  }, [props.galleryId, userId]);

  const reloadPhotos = () => {
    getOwnPhotos(props.galleryId, userId).then((ourPhotos) => {
      setPhotos(ourPhotos.docs);
    });
  };

  const navigateToComments = (photoId: string) => {
    history.push(`/${params.code}/photo/${photoId}`, {
      scrollTo: 'comments',
    });
  };

  return (
    <>
      <BreadcrumbHeader code={params.code}>
        <Typography>Your Photos</Typography>
      </BreadcrumbHeader>
      {photos.map((photo) => (
        <PhotoCard
          {...photo.data()}
          id={photo.id}
          galleryId={props.galleryId}
          onComment={navigateToComments}
          onDeleted={reloadPhotos}
        />
      ))}
    </>
  );
};
