import {Avatar, AvatarProps} from '@material-ui/core';
import * as colours from '@material-ui/core/colors';

const avatarColours = [
  colours.amber,
  colours.blue,
  colours.blueGrey,
  colours.brown,
  colours.cyan,
  colours.deepOrange,
  colours.deepPurple,
  colours.green,
  colours.indigo,
  colours.lightBlue,
  colours.lightGreen,
  colours.lime,
  colours.orange,
  colours.pink,
  colours.purple,
  colours.red,
  colours.teal,
  colours.yellow,
]

const generateColour = (value: string) => {
  let code = 1;
  for (let i=0; i < value.length; i++) {
    code += value.charCodeAt(i);
  }

  return avatarColours[code % avatarColours.length][700];
}

interface ColourfulAvatarProps extends AvatarProps {
  userId: string;
}

export const ColourfulAvatar = ({userId, ...rest}: ColourfulAvatarProps) => {
  return <Avatar {...rest} style={{backgroundColor: generateColour(userId || '')}} />;
};
