import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import firebase from 'firebase';
import {Config} from '../config';

enum ApiErrorType {
  CONNECTION_FAILED = 0,
  SERVER_ERROR = 1,
}

export class ApiError extends Error {
  // extends Error
  statusCode: number; // HTTP Status Code
  message: string; // Message
  data?: AxiosResponse<ErrorResponse> | AxiosRequestConfig;
  errorType?: ApiErrorType;

  constructor(
    statusCode: number,
    message: string,
    errorType?: ApiErrorType,
    data?: AxiosResponse | AxiosRequestConfig,
  ) {
    super();
    this.statusCode = statusCode;
    this.message = message;
    this.data = data;
    this.errorType = errorType;
  }
}

interface SingleErrorResponse {
  message?: string;
  error?: string;
}

interface MultiErrorResponse {
  message?: string;
  errors: {
    json: {
      [name: string]: string;
    };
  };
}

export type ErrorResponse = SingleErrorResponse | MultiErrorResponse;

export type ApiResponse<T> = AxiosResponse<T> & {pagination?: PaginationResult};

interface PaginationResult {
  total: number;
  total_pages: number;
  page: number;
  first_page: number;
  last_page: number;
  previous_page: number;
  next_page: number;
}

class ApiStore {
  csrfToken: string = '';

  private axios = axios.create({
    timeout: Config.api.timeout,
    headers: {
      Accept: 'application/json, text/plain, image/*',
    },
    // withCredentials: true,
    /*validateStatus: (status: number) => {
      return status >= 200 && status <= 400;
    },*/
  });

  constructor() {
    this.axios.interceptors.response.use(
      (response) => {
        if (response.status === 401) {
          return Promise.reject(response);
        }
        return response;
      },
      (error: AxiosError) => {
        if (error.response) {
          // Probable server error
          if (error.response.status >= 500) {
            console.error('Server Error?', error.response);
          }

          return Promise.reject(
            new ApiError(
              error.response.status,
              'Server Error',
              ApiErrorType.SERVER_ERROR,
              error.response,
            ),
          );
        } else if (error.request) {
          console.log(error);
          // No response received from the server
          if (error.code === 'ECONNABORTED' || error.code === 'ECONNREFUSED') {
            console.log('Connection Error: ' + error.message);
          }
          if (error.message.includes('Aborted')) {
            return;
          }
          // Couldn't connect to server
          return Promise.reject(
            new ApiError(0, 'Connection Timed Out', ApiErrorType.CONNECTION_FAILED, error.request),
          );
        }
        return Promise.reject(error);
      },
    );
  }

  async request<SuccessResponse>(
    request: AxiosRequestConfig,
    repeat = false,
  ): Promise<ApiResponse<SuccessResponse>> {
    if (!request.headers) {
      request.headers = {};
    }

    if (!request.baseURL) {
      request.baseURL = Config.api.base;
    }

    if (!request.headers) {
      request.headers = {};
    }

    if (request.method && request.method?.toUpperCase() !== 'GET') {
      // Inject the CSRF Token
      request.headers['X-Csrf-Token'] = this.csrfToken;
    }

    if (firebase.auth().currentUser) {
      const idToken = await firebase.auth().currentUser?.getIdToken();
      request.headers.Authorization = `Bearer ${idToken}`;
    }

    let response;
    try {
      response = await this.axios(request);
    } catch (e) {
      // If this was a server error of some kind log it so we can look at the issue
      if (e instanceof ApiError || e.status >= 500) {
        if (e.statusCode === 307) {
          // For some reason Axios is not following the 307 redirect, so force redirection
          const newRequest: AxiosRequestConfig = {};
          Object.assign(newRequest, request);
          newRequest.baseURL = undefined;
          newRequest.url = e.data.headers.location;
          console.log('following request', newRequest.url);
          return await this.request(newRequest);
        } else if (e.statusCode === 400 && !repeat) {
          // Possibly expired csrf token
          await this.request({url: '/users/me'});
          return await this.request(request, true);
        }
      }
      throw e;
    }

    if (response.headers['x-csrf-token']) {
      this.csrfToken = response.headers['x-csrf-token'];
    }

    let pagination;
    if (response.headers['x-pagination']) {
      try {
        pagination = JSON.parse(response.headers['x-pagination']) as PaginationResult;
      } catch (e) {
        console.error(e);
      }
    }

    return {
      ...response,
      data: response.data as SuccessResponse,
      pagination: pagination,
    };
  }
}

export default new ApiStore();
