import {Container, makeStyles, TextField, Typography} from '@material-ui/core';
import {FormEvent, useEffect, useState} from 'react';
import {useQueryClient} from 'react-query';
import {useHistory} from 'react-router';
import {getGallery} from '../../api/gallery';
import {ProgressButton} from '../../components/ProgressButton';
import {getCode} from '../../util';
import logo from '../../img/logo.png';

export const EnterCode = () => {
  const history = useHistory();
  const cache = useQueryClient();
  const classes = useStyles();
  const [codeValue, setCodeValue] = useState('');
  const [invalidCode, setInvalidCode] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    window.localStorage.removeItem('photoShareApp:lastCode');
  }, []);

  const updateCodeValue = (e: any) => {
    setCodeValue(e.target.value);
    // Check code validity:
    setInvalidCode(!/^((\d{8})|(\d{4}[\s-]\d{4}))$/.test(e.target.value));
  };

  const goToGallery = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    const code = getCode(codeValue);

    try {
      // Store the response
      cache.setQueryData(`/gallery/${code}`, await getGallery(code));
      history.replace(`/${code}`);
    } catch (e) {
      console.log(e);
      if (e.message === 'not-found') {
        setError('No such gallery. Please check the code is correct');
      } else {
        setError('Could not connect. Please try again');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component={'main'} maxWidth={'xs'}>
      <div className={classes.paper}>
        <img src={logo} alt={'Snappy'} className={classes.avatar} />
        <Typography component="h1" variant="h5">
          Snappy
        </Typography>
        <Typography component="h1" variant="h6" style={{marginTop: '0.5rem'}}>
          Enter the event code below
        </Typography>
        <form className={classes.form} noValidate={true} onSubmit={goToGallery}>
          <TextField
            variant={'outlined'}
            margin={'normal'}
            required={true}
            fullWidth={true}
            autoComplete={'eventCode'}
            autoFocus={true}
            inputMode={'numeric'}
            disabled={loading}
            inputProps={{
              style: {textAlign: 'center'},
            }}
            value={codeValue}
            onChange={updateCodeValue}
            error={!!error}
          />
          {!!error && (
            <Typography color={'error'} align={'center'}>
              {error}
            </Typography>
          )}
          <ProgressButton
            type="submit"
            size={'large'}
            fullWidth={true}
            variant="contained"
            color="primary"
            className={classes.submit}
            loading={loading}
            disabled={invalidCode}>
            Continue
          </ProgressButton>
        </form>
      </div>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    width: '64px',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
