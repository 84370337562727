import {Menu, MenuItem, MenuList, MenuProps} from '@material-ui/core';
import {useState} from 'react';
import {NameDialog} from '../NameDialog';

export const UserMenu = (props: MenuProps) => {
  const [showName, setShowName] = useState(false);

  const toggleShowName = () => {
    setShowName(!showName);
    if (props.onClose) {
      props.onClose({}, 'backdropClick');
    }
  };

  return (
    <>
      <Menu {...props}>
        <MenuList>
          <MenuItem onClick={toggleShowName}>Change Name</MenuItem>
        </MenuList>
      </Menu>
      <NameDialog noDescription={true} show={showName} onChanged={toggleShowName} />
    </>
  );
};
