import {
  AppBar,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {ArrowDropDown} from '@material-ui/icons';
import {observer} from 'mobx-react-lite';
import {useState} from 'react';
import {useUser} from '../../context/user';
import {ColourfulAvatar} from '../ColourfulAvatar';
import {GalleryOptionsMenu} from '../GalleryOptions';
import {UserMenu} from '../UserMenu';

export const Header = observer((props: {name: string}) => {
  const {name, userId} = useUser();
  const classes = useStyles();
  const [galleryAnchorEl, setGalleryAnchorEl] = useState<null | HTMLElement>(null);
  const [userAnchorEl, setUserAnchorEl] = useState<null | HTMLElement>(null);

  const openGalleryMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setGalleryAnchorEl(event.currentTarget);
  };

  const openUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setGalleryAnchorEl(null);
    setUserAnchorEl(null);
  };

  return (
    <AppBar position={'fixed'}>
      <Toolbar>
        <Typography
          variant={'h6'}
          aria-controls={'gallery-options'}
          aria-haspopup={true}
          className={classes.truncate}
          onClick={openGalleryMenu}>
          {props.name}
        </Typography>
        <IconButton color={'inherit'} onClick={openGalleryMenu}>
          <ArrowDropDown />
        </IconButton>
        <div className={classes.spacer} />
        <IconButton onClick={openUserMenu}>
          <ColourfulAvatar userId={userId}>{name.substr(0, 1)}</ColourfulAvatar>
        </IconButton>
      </Toolbar>
      <GalleryOptionsMenu
        id={'gallery-options'}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
        open={!!galleryAnchorEl}
        onClose={handleClose}
        anchorEl={galleryAnchorEl}
      />
      <UserMenu
        id={'user-menu'}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        open={!!userAnchorEl}
        onClose={handleClose}
        anchorEl={userAnchorEl}
      />
    </AppBar>
  );
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    spacer: {
      flexGrow: 1,
    },
    appLogo: {
      marginRight: theme.spacing(2),
    },
    truncate: {
      maxWidth: '60vw',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },
  }),
);
