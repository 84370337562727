import firebase from 'firebase/app';
import {Paper, Grid, makeStyles, Theme, createStyles, Typography} from '@material-ui/core';
import TimeAgo from 'react-timeago';
import {ColourfulAvatar} from '../ColourfulAvatar';

interface CommentProps {
  comment: string;
  name: string;
  user_id: string;
  posted_at: firebase.firestore.Timestamp;
}

export const Comment = ({comment, name, posted_at, user_id}: CommentProps) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <Grid container={true} alignItems={'center'} className={classes.header}>
        <Grid item={true}>
          <ColourfulAvatar userId={user_id} className={classes.avatar}>{name.substr(0, 1)}</ColourfulAvatar>
        </Grid>
        <Grid item={true} style={{flexGrow: 1}}>
          {name}
        </Grid>
        <Grid item={true}>
          <Typography variant={'subtitle2'} color={'textSecondary'}>
            <TimeAgo date={posted_at.toDate()} minPeriod={60} />
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="body2">{comment}</Typography>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    avatar: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      fontSize: 'small',
      marginRight: theme.spacing(1),
    },

    header: {
      marginBottom: theme.spacing(1),
    },
  }),
);
