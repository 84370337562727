import {
  Portal,
  Toolbar,
  Typography,
} from '@material-ui/core';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {observer} from 'mobx-react-lite';
import {useEffect, useState} from 'react';
import {useInfiniteQuery} from 'react-query';
import {useHistory, useParams} from 'react-router';
import {Virtuoso} from 'react-virtuoso';
import {getPhotoStream} from '../../api/gallery';
import {BreadcrumbHeader} from '../../components/BreadcrumbHeader';
import {EmptyGallery} from '../../components/EmptyGallery';
import {Footer} from '../../components/Footer';
import {LoadingIndicator} from '../../components/LoadingIndicator';
import {PhotoCard, PhotoCardPlaceholder} from '../../components/PhotoCard';
import {useUiStore} from '../../context/ui';
import {portal} from '../../layouts/portal';
import {Photo} from '../../types/gallery';

interface Props {
  galleryId: string;
  hideUploads: boolean;
}

// TODO: Error handling

export const PhotoGallery = observer((props: Props) => {
  const history = useHistory();
  const params = useParams<{code: string; tag?: string}>();
  const {sortMethod} = useUiStore();
  const [items, setItems] = useState<firebase.firestore.QueryDocumentSnapshot<Photo>[]>([]);

  const {data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading} = useInfiniteQuery<
    firebase.firestore.QuerySnapshot<Photo>
  >(
    `gallery/${props.galleryId}/${sortMethod}/${params.tag}`,
    ({pageParam}) =>
      getPhotoStream(props.galleryId, {
        sorting: sortMethod,
        lastDoc: pageParam,
        tag: params.tag,
      }),
    {
      getNextPageParam: (lastPage, pages) =>
        !lastPage.empty ? lastPage.docs[lastPage.docs.length - 1] : undefined,
    },
  );

  const reloadPhotos = () => {
    window.location.reload();
  };

  useEffect(() => {
    setItems(
      data?.pages.reduce((previous, page) => {
        if (page.docs) {
          previous.push(...page.docs);
        }
        return previous;
      }, [] as firebase.firestore.QueryDocumentSnapshot<Photo>[]) || [],
    );
  }, [data?.pages]);

  const navigateToComments = (photoId: string) => {
    history.push(`/${params.code}/photo/${photoId}`, {
      scrollTo: 'comments',
    });
  };

  const itemRenderer = (index: number, data: firebase.firestore.QueryDocumentSnapshot<Photo>) => {
    const photo = data.data();
    return (
      <PhotoCard
        onComment={navigateToComments}
        onDeleted={reloadPhotos}
        galleryId={props.galleryId}
        docRef={data.ref}
        id={data.id}
        {...photo}
      />
    );
  };

  return (
    <>
      {params.tag && <TagHeader tag={params.tag} />}
      <Virtuoso
        //startReached={loadNewest}
        useWindowScroll={true}
        data={items}
        endReached={() => fetchNextPage()}
        overscan={200}
        itemContent={itemRenderer}
        computeItemKey={(index) => items[index]?.id || index}
        components={{
          Footer: () => {
            if (isFetchingNextPage) {
              return <LoadingIndicator tiny={true} />;
            } else if (hasNextPage) {
              return <PhotoCardPlaceholder />;
            } else {
              return null;
            }
          },

          EmptyPlaceholder: isLoading ? () => (<LoadingIndicator />) : EmptyGallery,
        }}
      />
      <Portal container={portal.current}>
        <Toolbar />
        <Toolbar />
        <Footer galleryId={props.galleryId} hideUploads={props.hideUploads} />
      </Portal>
    </>
  );
});

export const TagHeader = ({tag}: {tag: string}) => {
  const params = useParams<{code: string}>();

  return (
    <BreadcrumbHeader code={params.code}>
        <Typography>
          Viewing <strong>#{tag}</strong>
        </Typography>
    </BreadcrumbHeader>
  )
};
