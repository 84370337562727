import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import {green} from '@material-ui/core/colors';
import {Skeleton} from '@material-ui/lab';
import firebase from 'firebase/app';
import React, {useEffect, useRef, useState} from 'react';
import {addUploadedPhoto, removePhotoUpload, uploadNewPhoto} from '../../api/photos';
import {useUser} from '../../context/user';
import {ProgressButton} from '../ProgressButton';

interface UploadProps {
  show: boolean;
  onClose: () => void;
  onPhotoUploaded: (photoId: string) => void;
  galleryId: string;
  file?: File;
}

export const PhotoUploadDialog = (props: UploadProps) => {
  const user = useUser();
  const [previewUrl, setPreviewUrl] = useState('');
  const [progress, setProgress] = useState(0);
  const [processed, setProcessed] = useState(false);
  const uploadTaskRef = useRef<firebase.storage.UploadTask>();
  const [uploaded, setUploaded] = useState('');
  const [caption, setCaption] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const classes = useStyles();

  const startUploading = async (file: File) => {
    return new Promise<string>((resolve, reject) => {
      uploadTaskRef.current = uploadNewPhoto(user.userId, props.galleryId, file);
      uploadTaskRef.current?.on(firebase.storage.TaskEvent.STATE_CHANGED, {
        next: (snapshot) => {
          const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(percent);
        },
        error: (error) => {
          uploadTaskRef.current = undefined;
          setError(error.name);
          setProcessed(true);
          reject(error.message);
        },
        complete: () => {
          const path = uploadTaskRef.current!.snapshot.ref.fullPath;
          setProgress(100);
          setUploaded(path);
          uploadTaskRef.current = undefined;
          setProcessed(true);
          resolve(path);
        },
      });
    });
  };

  useEffect(() => {
    if (uploadTaskRef.current) {
      try {
        uploadTaskRef.current?.cancel();
      } catch (e) {
        // Pass
      }
    }
    if (props.file) {
      setPreviewUrl(window.URL.createObjectURL(props.file));
      // Immediately start uploading the photo
      startUploading(props.file).catch(() => {});
    } else {
      setPreviewUrl('');
    }
    setError('');
    setProgress(0);
    setProcessed(false);
    setCaption('');
    setUploaded('');
  }, [props.file]);

  const uploadPhoto = async () => {
    setLoading(true);
    setError('');

    try {
      let url;
      if (uploaded) {
        url = uploaded;
      } else if (uploadTaskRef.current) {
        // We are currently uploading so wait for it to finish to fail
        const result = await uploadTaskRef.current;
        url = result.ref.fullPath;
      } else {
        url = await startUploading(props.file!);
      }

      // Then ask firestore to add it and return the photo id
      const result = await addUploadedPhoto(props.galleryId, url, caption, user.name);
      setUploaded('');
      await new Promise((resolve) => setTimeout(resolve, 500));
      props.onPhotoUploaded(result);
      return result;
    } catch (e) {
      console.log(e);
      setError('There was a problem uploading your image');
    } finally {
      setLoading(false);
    }
  };

  const scrollToView = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.scrollIntoView();
  };

  const cancelUpload = () => {
    props.onClose();
    setCaption('');
    if (uploaded) {
      // Delete the photo if uploaded
      removePhotoUpload(user.userId, props.galleryId, uploaded).catch(() => {
        console.log('left photo dangling');
      });
    }
    setUploaded('');
    setProcessed(false);
    uploadTaskRef.current = undefined;
  };

  const updateCaption = (e: any) => {
    setCaption(e.target.value);
  };

  return (
    <Dialog
      disableEnforceFocus={true}
      open={props.show}
      onClose={props.onClose}
      maxWidth={'sm'}
      fullWidth={true}
      className={classes.dialog}
      scroll={'body'}>
      <DialogTitle id="name-dialog-title">Upload A Photo</DialogTitle>
      <DialogContent>
        <div className={classes.imageContainer}>
          {!previewUrl ? (
            <Skeleton height={100} width={'100%'} />
          ) : (
            <img src={previewUrl} alt={'preview'} className={classes.image} />
          )}
        </div>
        <LinearProgress
          value={!processed && error ? 100 : progress}
          variant={!processed && error ? 'indeterminate' : 'determinate'}
          color={processed && error ? 'secondary' : 'primary'}
          className={processed ? classes.greenProgress : undefined}
        />
        <br />
        <br />
        <TextField
          placeholder={'Give your photo a caption'}
          required={true}
          fullWidth={true}
          autoComplete={'photo-caption'}
          autoFocus={true}
          multiline={true}
          value={caption}
          error={!!error}
          helperText={error}
          onChange={updateCaption}
          disabled={loading}
          inputProps={{
            onFocus: scrollToView,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button color="default" onClick={cancelUpload} disabled={loading}>
          Cancel
        </Button>
        <ProgressButton
          onClick={uploadPhoto}
          loading={loading}
          disabled={!processed}
          color={'primary'}>
          {!processed ? 'Processing' : 'Upload'}
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    greenProgress: {
      backgroundColor: green[500],
      '& > .MuiLinearProgress-barColorPrimary': {
        backgroundColor: green[500],
      },
    },

    dialog: {},

    image: {
      height: '50vh',
      width: '100%',
      objectFit: 'contain',
    },

    imageContainer: {
      maxHeight: '50vh',
      width: '100%',
      textAlign: 'center',
      background: '#000',
    },
  }),
);
