import firebase from 'firebase/app';
import {v4} from 'uuid';
import {Config} from '../config';
import {functions} from '../firebase';
import {Photo, PhotoComment} from '../types/gallery';
import api from './index';

export const removePhotoUpload = async (userId: string, galleryId: string, path: string) => {
  const urlParts = path.split('/');
  const filename = urlParts[urlParts.length - 1];
  const storageRef = firebase.storage().ref(`photos/${userId}/${galleryId}/${filename}`);
  await storageRef.delete();
};

/** Upload a photo to Storage */
export const uploadNewPhoto = (userId: string, galleryId: string, file: File) => {
  const uuid = v4();
  let fileExtDot = file.name.lastIndexOf('.');
  let ext = file.name.substr(fileExtDot, file.name.length) || '.jpg';
  const storageRef = firebase.storage().ref(`photos/${userId}/${galleryId}/${uuid}${ext}`);
  return storageRef.put(file, {
    contentType: file.type,
  });
};

/** Add the photo to the database */
export const addUploadedPhoto = async (
  galleryId: string,
  url: string,
  caption: string,
  name: string,
) => {
  const payload = {
      galleryId: galleryId,
      url: url,
      caption: caption,
      name: name,
  };
  let result;

  if (Config.apiMethod === 'appengine') {
    result = (await api.request<any>({
      method: 'post',
      url: '/addPhoto',
      data: {
        data: payload
      }
    })).data;
  } else {
    result = await functions().httpsCallable('addPhoto', {
      timeout: Config.api.timeout,
    })(payload);
  }
  return result.data.photoId as string;
};

type PhotoCommentCallback = (
  comments: firebase.firestore.QueryDocumentSnapshot<PhotoComment>[],
) => void;

type PhotoCommentErrorCallback = (error: firebase.firestore.FirestoreError) => void;

export const watchPhotoComments = (
  photoId: string,
  galleryId: string,
  onResult: PhotoCommentCallback,
  onError?: PhotoCommentErrorCallback,
) => {
  const comments = firebase
    .firestore()
    .collection(`photos/${galleryId}/photos/${photoId}/comments`);
  const query = comments.orderBy('posted_at', 'desc').limit(100);
  return query.onSnapshot((snapshot) => {
    onResult(snapshot.docs as firebase.firestore.QueryDocumentSnapshot<PhotoComment>[]);
  }, onError);
};

export const addPhotoComment = async (
  photoId: string,
  galleryId: string,
  comment: Partial<PhotoComment>,
) => {
  const comments = firebase
    .firestore()
    .collection(`photos/${galleryId}/photos/${photoId}/comments`);
  await comments.add(comment);
};

export const getOwnPhotos = async (galleryId: string, userId: string) => {
  const query = firebase
    .firestore()
    .collection(`photos/${galleryId}/photos`)
    .where('user_id', '==', userId)
    .orderBy('posted_at', 'desc')
    .limit(200);

  return (await query.get()) as firebase.firestore.QuerySnapshot<Photo>;
};

export const deletePhoto = async (photoId: string, galleryId: string) => {
  const payload = {
      galleryId,
      photoId,
  };

  if (Config.apiMethod === 'appengine') {
    await api.request<any>({
      method: 'post',
      url: '/deletePhoto',
      data: {
        data: payload
      }
    });
  } else {
    await functions().httpsCallable('deletePhoto', {
      timeout: Config.api.timeout,
    })(payload);
  }
  return true;
};

export const changePhotoCaption = async (photoId: string, galleryId: string, caption: string) => {
  const payload = {
      galleryId,
      photoId,
      caption
  };

  if (Config.apiMethod === 'appengine') {
    await api.request<any>({
      method: 'post',
      url: '/updatePhoto',
      data: {
        data: payload
      }
    });
  } else {
    await functions().httpsCallable('updatePhoto', {
      timeout: Config.api.timeout,
    })(payload);
  }
  return true;
}
