import {
  Button,
  ButtonProps,
  createStyles,
  makeStyles,
  Theme,
  CircularProgress,
} from '@material-ui/core';
import {green, red} from '@material-ui/core/colors';
import clsx from 'clsx';

interface ProgressButtonProps {
  loading: boolean;
  status?: 'success' | 'error';
}

export const ProgressButton = (props: ProgressButtonProps & ButtonProps) => {
  const classes = useStyles();
  const buttonClassName = clsx({
    [classes.buttonSuccess]: props.status === 'success',
    [classes.buttonError]: props.status === 'error',
  });
  return (
    <div className={classes.wrapper}>
      <Button className={buttonClassName} {...props} disabled={props.loading || props.disabled} />
      {props.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    buttonError: {
      backgroundColor: red[500],
      '&:hover': {
        backgroundColor: red[700],
      },
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);
