import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  createStyles,
  makeStyles,
  Modal,
  Theme,
  Typography,
} from '@material-ui/core';
import CommentIcon from '@material-ui/icons/Comment';
import FavoriteIcon from '@material-ui/icons/Favorite';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ShareIcon from '@material-ui/icons/Share';
import {Skeleton} from '@material-ui/lab';
import firebase from 'firebase/app';
import {useEffect, useState} from 'react';
import {useQueryClient} from 'react-query';
import {useParams} from 'react-router';
import {Link} from 'react-router-dom';
import TimeAgo from 'react-timeago';
import {photoReaction} from '../../api/gallery';
import {changePhotoCaption, deletePhoto} from '../../api/photos';
import {useUser} from '../../context/user';
import {Photo} from '../../types/gallery';
import {ColourfulAvatar} from '../ColourfulAvatar';
import {PhotoActions} from '../PhotoActions';
import {TextInputDialog} from '../TextInputDialog';
import {renderText} from '../TextRender';


const webShareSupported = 'canShare' in navigator;


interface PhotoProps extends Photo {
  id: string;
  galleryId: string;
  docRef?: firebase.firestore.DocumentReference;
  onComment: (id: string) => void;
  onDeleted?: () => void;
}

export const PhotoCard = (props: PhotoProps) => {
  const [fullscreen, setFullscreen] = useState(false);
  const params = useParams<{code: string}>();
  const user = useUser();
  const classes = useStyles();
  const [liked, setLiked] = useState(props.likes && props.likes[user.userId]);
  const [changedCaption, setChangedCaption] = useState<string | undefined>();
  const [editCaption, setEditCaption] = useState(false);
  const queryClient = useQueryClient();
  const CardAvatar = (
    <ColourfulAvatar userId={props.user_id}>{props.name.substr(0, 1)}</ColourfulAvatar>
  );

  useEffect(() => {
    setChangedCaption(undefined);
  }, [props.caption]);

  useEffect(() => {
    setLiked(props.likes && props.likes[user.userId]);
  }, [props.likes]);

  const toggleLike = () => {
    let previous = liked;
    if (liked) {
      setLiked(undefined);
      photoReaction(props.galleryId, props.id, null).catch((e) => {
        setLiked(previous);
        console.log(e);
      });
    } else {
      // Send a like
      setLiked('heart');
      photoReaction(props.galleryId, props.id, 'heart').catch((e) => {
        setLiked(previous);
        console.log(e);
      });
    }
  };

  const removePhoto = async () => {
    await deletePhoto(props.id, props.galleryId);
    if (props.onDeleted) {
      props.onDeleted();
    }
  };

  const showFullscreenPhoto = () => {
    setFullscreen(true);
  };

  const hideFullscreenPhoto = () => {
    setFullscreen(false);
  };

  const showEditCaption = () => {
    setEditCaption(true);
  };

  const closeEditCaption = () => {
    setEditCaption(false);
  };

  const editPhotoCaption = (newCaption: string) => {
    changePhotoCaption(props.id, props.galleryId, newCaption).catch((e) => {
      console.log(e);
    });
    setEditCaption(false);
    // Invalidate the query cache
    queryClient.invalidateQueries();
    setChangedCaption(newCaption);
  };

  const onShare = async () => {
    try {
      const blob = await fetch(props.url + '?t=1').then(res => res.blob());
      const files = [
        new File([blob], 'image.jpg', {
          type: blob.type,
        }),
      ];
      if ((navigator as any).canShare && (navigator as any).canShare({files})) {
        await navigator.share({
          files,
          title: props.caption,
          text: `Share ${props.caption}`,
        } as any);
      }
    } catch (e) {
      // @ts-ignore
      window.location = props.url;
    }
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={CardAvatar}
        title={props.name}
        subheader={<TimeAgo date={props.posted_at.toDate()} minPeriod={60} />}
        action={
          props.user_id === user.userId ? (
            <PhotoActions onDelete={removePhoto} onEdit={showEditCaption} />
          ) : undefined
        }
      />
      {fullscreen && (
        <Modal open={true} className={classes.fullscreenModal}>
          <div className={classes.fullscreenModalContainer} onDoubleClick={hideFullscreenPhoto}>
            <img src={props.url} alt={props.caption} style={{maxWidth: '100%', maxHeight: '100%'}} />
          </div>
        </Modal>
      )}
      {editCaption && (
        <TextInputDialog
          show={true}
          title={'Edit Photo Caption'}
          initialValue={props.caption}
          placeholder={'Photo Caption'}
          message={''}
          onCancelled={closeEditCaption}
          onChanged={editPhotoCaption}
          inputProps={{
            multiline: true,
            rowsMax: 3,
          }}
          dialogProps={{
            fullWidth: true,
            maxWidth: 'md',
          }}
        />
      )}
      <Link to={`/${params.code}/photo/${props.id}`} onDoubleClick={showFullscreenPhoto}>
        <CardMedia className={classes.media} image={props.url} title={props.caption} />
      </Link>
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {renderText(changedCaption || props.caption, params.code)}
        </Typography>
      </CardContent>
      <CardActions disableSpacing={true} className={classes.actions}>
        <Button
          size={'small'}
          variant={'text'}
          startIcon={liked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          onClick={toggleLike}>
          {props.total_likes === 0
            ? 'Like'
            : props.total_likes + ' Like' + (props.total_likes > 1 ? 's' : '')}
        </Button>
        {webShareSupported ? (
          <Button startIcon={<ShareIcon />} size={'small'} variant={'text'} onClick={onShare}>
            Share
          </Button>
        ) : (
          <Button startIcon={<DownloadIcon />} size={'small'} variant={'text'} href={props.url} download={`${props.caption}.jpg`}>
            Get
          </Button>
        )}
        <Button startIcon={<DownloadIcon />} size={'small'} variant={'text'} href={props.url} download={`${props.caption}.jpg`}>
          Download
        </Button>
        <Button
          size={'small'}
          variant={'text'}
          startIcon={<CommentIcon />}
          onClick={() => props.onComment(props.id)}>
          {!props.total_comments
            ? 'Comment'
            : props.total_comments + ' Comment' + (props.total_comments > 1 ? 's' : '')}
        </Button>
      </CardActions>
    </Card>
  );
};

export const PhotoCardPlaceholder = () => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={<Skeleton animation={'wave'} variant={'circle'} height={40} width={40} />}
        title={<Skeleton animation="wave" height={10} width="80%" style={{marginBottom: 6}} />}
        subheader={<Skeleton animation="wave" height={10} width="40%" style={{marginBottom: 6}} />}
      />
      <Skeleton animation="wave" variant="rect" className={classes.media} />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          <Skeleton animation="wave" height={10} style={{marginBottom: 6}} />
        </Typography>
      </CardContent>
      <CardActions disableSpacing={true} className={classes.actions}>
        <Skeleton animation="wave" height={10} style={{marginBottom: 6}} />
      </CardActions>
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      // height: '25vh',
    },

    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
      backgroundSize: 'contain',
      backgroundColor: '#000',
    },

    actions: {
      flex: 1,
      justifyContent: 'space-between',
    },

    fullscreenModal: {
      backgroundColor: '#000',
    },

    fullscreenModalContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);
