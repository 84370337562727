import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {useState} from 'react';
import {DeleteButtonMenuItem} from '../DeleteButton';
import {TextInputDialog} from '../TextInputDialog';

interface PhotoActionsProps {
  onEdit: () => void;
  onDelete: () => void;
}

export const PhotoActions = (props: PhotoActionsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEditItem = () => {
    handleClose();
    props.onEdit();
  };

  const onDeleteItem = () => {
    handleClose();
    props.onDelete();
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
        open={!!anchorEl}
        onClose={handleClose}
        anchorEl={anchorEl}>
        <MenuItem onClick={onEditItem}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>Edit Caption</ListItemText>
        </MenuItem>
        <DeleteButtonMenuItem
          onClick={onDeleteItem}
          title={'Delete Photo?'}
          message={'Are you sure you want to delete this photo?'}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>Delete Photo</ListItemText>
        </DeleteButtonMenuItem>
      </Menu>
    </>
  );
};
