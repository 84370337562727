import {
  Dialog,
  DialogTitle,
  Fab,
  FabProps,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {PhotoCamera} from '@material-ui/icons';
import CameraIcon from '@material-ui/icons/Camera';
import PortraitIcon from '@material-ui/icons/Portrait';
import {ChangeEvent, useRef, useState} from 'react';
import {useQueryClient} from 'react-query';
import {useHistory, useParams} from 'react-router';
import {Config} from '../../config';
import {getDeviceSystem} from '../../util';
import {DesktopPhotoCaptureDialog} from '../DesktopPhotoCaptureDialog';
import {PhotoUploadDialog} from '../PhotoUploadDialog';

interface CameraButtonProps extends Partial<FabProps> {
  galleryId: string;
}

export const CameraButton = (props: CameraButtonProps) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const params = useParams<{code: string}>();
  const [showUploader, setShowUploader] = useState(false);
  const inputDirectRef = useRef<HTMLInputElement>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const [choiceDialog, setChoiceDialog] = useState(false);
  const [showDesktopDialog, setShowDesktopDialog] = useState(false);

  const showFileDialog = () => {
    setChoiceDialog(false);
    setSelectedFile(undefined);
    inputFileRef.current?.click();
  };

  const showCameraDialog = () => {
    const system = getDeviceSystem();
    setChoiceDialog(false);
    setSelectedFile(undefined);
    if (system === 'desktop') {
      setShowDesktopDialog(true);
    } else {
      inputDirectRef.current?.click();
    }

  };

  const hidePhotoUploader = () => {
    setShowUploader(false);
    setChoiceDialog(false);
  };

  const onDesktopFileSelect = (file: File) => {
    if (file.size > Config.limits.fileSize) {
      // TODO: Show error dialog
      alert('The selected image is too big to upload');
      return;
    }
    setShowDesktopDialog(false);
    setSelectedFile(file);
    setShowUploader(true);
  }

  const onFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];
    if (!file) {
      return;
    }
    onDesktopFileSelect(file);
  };

  const closeDesktopDialog = () => {
    setShowDesktopDialog(false);
  }

  const onPhotoUploaded = (photoId: string) => {
    setShowUploader(false);
    queryClient.clear();
    history.push(`/${params.code}`);
    setSelectedFile(undefined);
  };

  const showChoiceDialog = () => {
    const system = getDeviceSystem();
    if (system !== 'ios') {
      // On Android: Give the option
      setChoiceDialog(true);
    } else {
      // On IOS: Always show the inputFileRef
      // On Web: Always show the inputFileRef
      showFileDialog();
    }
  };

  return (
    <>
      <PhotoUploadDialog
        show={showUploader}
        galleryId={props.galleryId}
        onClose={hidePhotoUploader}
        onPhotoUploaded={onPhotoUploaded}
        file={selectedFile}
      />
      {showDesktopDialog && (
        <DesktopPhotoCaptureDialog onChange={onDesktopFileSelect} onBack={closeDesktopDialog} />
      )}
      <input
        type="file"
        accept="image/*"
        capture="camera"
        style={{display: 'none'}}
        ref={inputDirectRef}
        onChange={onFileSelect}
      />
      <input
        type="file"
        accept="image/*"
        style={{display: 'none'}}
        ref={inputFileRef}
        onChange={onFileSelect}
      />
      <Dialog open={choiceDialog} onClose={hidePhotoUploader}>
        <DialogTitle>Select Photo</DialogTitle>
        <List>
          <ListItem onClick={showCameraDialog} button={true}>
            <ListItemIcon>
              <CameraIcon />
            </ListItemIcon>
            <ListItemText>Take Photo</ListItemText>
          </ListItem>
          <ListItem onClick={showFileDialog} button={true}>
            <ListItemIcon>
              <PortraitIcon />
            </ListItemIcon>
            <ListItemText>Choose from Gallery</ListItemText>
          </ListItem>
        </List>
      </Dialog>
      <Fab color="secondary" aria-label="add" onClick={showChoiceDialog} {...props}>
        <PhotoCamera />
      </Fab>
    </>
  );
};
