import {
  ListItem,
  Menu,
  MenuItem,
  MenuList,
  MenuProps,
  TextField,
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {observer} from 'mobx-react-lite';
import {KeyboardEvent, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import {useUiStore} from '../../context/ui';
import {SortMethod} from '../../stores/ui';

export const GalleryOptionsMenu = observer((props: MenuProps) => {
  const history = useHistory();
  const {code} = useParams<{code: string}>();
  const {sortMethod, setSortMethod} = useUiStore();
  const [search, setSearch] = useState('');

  const closeGallery = () => {
    history.push('/');
  }

  const changeText = (e: any) => {
    setSearch(e.target.value);
  };

  const changeSortMethod = (method: SortMethod) => {
    setSortMethod(method);
    if (props.onClose) {
      props.onClose({}, 'escapeKeyDown');
    }
  }

  const checkKey = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      const matches = (search || '').match(/#?(\w+)$/);
      const hashtag = matches ? matches![1] : '';

      if (hashtag) {
        history.push(`/${code}/tag/${hashtag}`);
      }
      setSearch('');
      if (props.onClose) {
        props.onClose(e, 'escapeKeyDown');
      }
    }
  };

  return (
    <Menu {...props} keepMounted={true}>
      <MenuList>
        <MenuItem disabled={true}>Sort By</MenuItem>
        <MenuItem onClick={() => changeSortMethod(SortMethod.MOST_RECENT)}>
          {sortMethod === SortMethod.MOST_RECENT && <FiberManualRecordIcon fontSize={'small'} />}{' '}
          Most Recent
        </MenuItem>
        <MenuItem onClick={() => changeSortMethod(SortMethod.MOST_LIKES)}>
          {sortMethod === SortMethod.MOST_LIKES && <FiberManualRecordIcon fontSize={'small'} />}{' '}
          Most Likes
        </MenuItem>
        <MenuItem disabled={true}>Search By Hashtag</MenuItem>
        <ListItem>
          <TextField
            variant={'outlined'}
            placeholder={'Name'}
            fullWidth={true}
            size={'small'}
            inputProps={{
              onKeyUp: checkKey,
            }}
            onChange={changeText}
            value={search}
          />
        </ListItem>
        <MenuItem onClick={closeGallery}>Close Gallery</MenuItem>
      </MenuList>
    </Menu>
  );
});
