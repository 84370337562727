import {Breadcrumbs, createStyles, IconButton, makeStyles, Theme} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {PropsWithChildren} from 'react';
import {useHistory} from 'react-router';

export const BreadcrumbHeader = ({children, code}: PropsWithChildren<{ code: string }>) => {
  const classes = useStyles();
  const history = useHistory();

  const goBack = () => {
    history.push(`/${code}`);
  };

  return (
    <div className={classes.root}>
      <IconButton onClick={goBack}>
        <ArrowBackIcon />
      </IconButton>

      <Breadcrumbs className={classes.breadcrumb}>
        {children}
      </Breadcrumbs>
    </div>
  );
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '-12px',
    },

    breadcrumb: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      // height: '25vh',
    },
  }),
);
