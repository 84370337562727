import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

const firebaseConfig = require('./firebase.json');

export const initializeFirebase = () => {
  firebase.initializeApp(firebaseConfig);
  /*if (process.env.NODE_ENV === 'development') {
    firebase.auth().useEmulator('http://localhost:5055');
    firebase.firestore().useEmulator('localhost', 5051);
    firebase.storage().useEmulator('localhost', 5054);
    firebase.functions().useEmulator('localhost', 5050);
  }
   */
};

export const functions = () => {
  if (process.env.NODE_ENV === 'development') {
    const func = firebase.app().functions('europe-west1');
    func.useEmulator('localhost', 5050);
    return func;
  } else {
    return firebase.app().functions('europe-west1');
  }
};
