import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import {PhotoCamera} from '@material-ui/icons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {useEffect, useRef, useState} from 'react';
import {v4} from 'uuid';

export const DesktopPhotoCaptureDialog = (props: {
  onChange: (file: File) => void;
  onBack: () => void;
}) => {
  const video = useRef<HTMLVideoElement>(null);
  const canvas = useRef<HTMLCanvasElement>(null);
  const [error, setError] = useState('');
  const [sizes, setSizes] = useState({width: 1280, height: 720});

  const getMediaDevices = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {width: 1280, height: 720, facingMode: 'user'},
        audio: false,
      });
      if (!video.current) {
        return;
      }
      video.current.srcObject = stream;
      await video.current.play();
      if (stream.getVideoTracks().length > 0) {
        const videoSize = stream.getVideoTracks()[0].getConstraints();
        let height, width;

        if (videoSize.height && (videoSize.height as any).max) {
          height = (videoSize.height as any).max;
        } else {
          height = (videoSize.height as any) || 720;
        }
        if (videoSize.width && (videoSize.width as any).max) {
          width = (videoSize.width as any).max;
        } else {
          width = (videoSize.width as any) || 1280;
        }
        setSizes({
          height,
          width,
        });
      }
    } catch (e) {
      if (e.name === 'NotAllowedError') {
        setError('You need to allow access to your camera in order to take a photo');
      } else {
        setError('An error occurred trying to open your camera');
      }
    }
  };

  useEffect(() => {
    getMediaDevices();
  }, []);

  useEffect(() => {
    const videoRef = video.current;
    return () => {
      if (videoRef) {
        videoRef.pause();
        videoRef.srcObject = null;
      }
    }
  }, [video.current]);

  const takePhoto = async () => {
    const context = canvas.current!.getContext('2d')!;
    canvas.current!.width = sizes.width;
    canvas.current!.height = sizes.height;
    context.drawImage(video.current!, 0, 0, sizes.width, sizes.height);
    const blob = await new Promise<Blob | null>((resolve) => {
      canvas.current!.toBlob(resolve, 'image/jpeg');
    });
    if (!blob) {
      console.log('no blob made');
      return;
    }
    const file = new File([blob], v4() + '.jpg', {
      type: 'image/jpeg',
    });
    props.onChange(file);
  };

  return (
    <Dialog open={true} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle>
        <IconButton onClick={props.onBack}>
          <ArrowBackIcon />
        </IconButton>
        Take a Photo
      </DialogTitle>
      <DialogContent>
        {error ? (
          <div style={{textAlign: 'center', marginBottom: '1rem'}}>
            <Typography>{error}</Typography>
            <Button onClick={getMediaDevices}>Try Again</Button>
          </div>
        ) : (
          <div style={{textAlign: 'center'}}>
            <video ref={video} style={{width: '100%'}} />
            <canvas ref={canvas} style={{display: 'none'}} />
            <IconButton onClick={takePhoto}>
              <PhotoCamera />
            </IconButton>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
