import firebase from 'firebase/app';
import 'firebase/auth';
import {action, makeObservable, observable} from 'mobx';

export class UserStore {
  userId = '';
  name = '';
  deviceToken = '';

  constructor() {
    makeObservable(this, {
      name: observable,
      userId: observable,
      changeName: action,
    });
    this.name = window.localStorage.getItem('photoShareApp:name') || '';
    this.deviceToken = window.localStorage.getItem('photoShareApp:token') || '';
  }

  setDeviceToken = (token: string) => {
    window.localStorage.setItem('photoShareApp:token', token);
    this.deviceToken = token;
  };

  changeName = (name: string) => {
    window.localStorage.setItem('photoShareApp:name', name);
    this.name = name;
    if (this.name) {
      firebase.auth().currentUser?.updateProfile({displayName: this.name});
    }
  };

  signIn = async (token: string) => {
    const cred = await firebase.auth().signInWithCustomToken(token);
    if (this.name) {
      cred.user?.updateProfile({displayName: this.name});
    }
    if (cred.user?.uid) {
      this.userId = cred.user?.uid;
    }
  };
}

export const userStore = new UserStore();
