import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import {useState} from 'react';
import {useUser} from '../../context/user';

interface NameDialogProps {
  show: boolean;
  onChanged?: () => void;
  noDescription?: boolean;
}

export const NameDialog = ({show, onChanged, noDescription}: NameDialogProps) => {
  const {name, changeName} = useUser();
  const [newName, setNewName] = useState(name);
  const [error, setError] = useState('');

  const updateNameValue = (e: any) => {
    setNewName(e.target.value);
  };

  const updateName = () => {
    // Strip leading and trailing whitespace
    let tempName = newName.trim();

    if (tempName.length < 2) {
      setError('Name must be at least 3 characters');
      return;
    }

    if (/[~!@#$%^&*()`,./><}{\][=+]/.test(tempName)) {
      setError('Name contains invalid characters');
      return;
    }

    if (!/([\u0000-\u0019\u0021-\uFFFF\s])+/gu.test(tempName)) {
      setError('Name contains invalid characters');
      return;
    }

    changeName(tempName);
    if (onChanged) {
      onChanged();
    }
  };

  const cancelUpdate = () => {
    if (onChanged) {
      onChanged();
    }
  }

  return (
    <Dialog open={show} aria-labelledby="name-dialog-title">
      <DialogTitle id="name-dialog-title">Enter Your Name</DialogTitle>
      <DialogContent>
        {!noDescription ? (
          <DialogContentText>
            We just need your name so that everyone knows who took that awesome pic!
          </DialogContentText>
        ) : (
          <DialogContentText>
            Your previous comments and photos will still have your old name
          </DialogContentText>
        )}
        <br />
        <br />
        <TextField
          variant={'outlined'}
          placeholder={'Name'}
          required={true}
          fullWidth={true}
          autoComplete={'name'}
          autoFocus={true}
          error={!!error}
          helperText={error}
          value={newName}
          onChange={updateNameValue}
        />
      </DialogContent>
      <DialogActions>
        {noDescription && (
          <Button onClick={cancelUpdate} color="primary">
            Cancel
          </Button>
        )}
        <Button onClick={updateName} color="primary" disabled={newName.length === 0}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};
