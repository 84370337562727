export const getCode = (code: string) => {
  return code.replace(/-\s/, '');
};

export const getDeviceSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
  if (/windows phone/i.test(userAgent)) {
    return 'android';
  } else if (/android/i.test(userAgent)) {
    return 'android';
  } else if (/iPad|iPhone|iPod|ios/i.test(userAgent) && !window.MSStream) {
    return 'ios';
  } else {
    return 'desktop';
  }
};

export const setFavicon = (href: string) => {
    const link: HTMLLinkElement = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = href;
    document.getElementsByTagName('head')[0].appendChild(link);
};
