import {AppBar, Button, createStyles, makeStyles, Theme, Toolbar} from '@material-ui/core';
import {useParams} from 'react-router';
import {Link as RouterLink} from 'react-router-dom';
import {CameraButton} from '../CameraButton';

export const Footer = ({galleryId, hideUploads}: {galleryId: string, hideUploads: boolean}) => {
  const classes = useStyles();
  const params = useParams<{code: string}>();

  if (hideUploads) {
    return null;
  }

  return (
    <>
      <AppBar position={'fixed'} className={classes.appBar}>
        <Toolbar>
          <Button color={'inherit'} component={RouterLink} to={`/${params.code}/my`}>My Photos</Button>
          <CameraButton className={classes.fabButton} galleryId={galleryId} />
        </Toolbar>
      </AppBar>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      top: 'auto',
      bottom: 0,
    },
    fabButton: {
      position: 'absolute',
      zIndex: 1,
      top: -30,
      left: 0,
      right: 0,
      margin: '0 auto',
    },
  }),
);
