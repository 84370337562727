import {
  DialogContent,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Button,
  MenuItem,
} from '@material-ui/core';
import {PropsWithChildren, useState} from 'react';

interface DeleteButtonProps {
  title: string;
  message: string;
  onClick: () => void;
}

export const DeleteButtonMenuItem = (props: PropsWithChildren<DeleteButtonProps>) => {
  const [showDialog, setShowDialog] = useState(false);

  const showConfirmation = () => {
    setShowDialog(true);
  };

  const handleCancel = () => {
    setShowDialog(false);
  };

  const handleOk = () => {
    setShowDialog(false);
    props.onClick();
  };

  return (
    <>
      {showDialog && (
        <Dialog
          open={true}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          maxWidth={'sm'}
          aria-labelledby={'confirmation-dialog-title'}>
          <DialogTitle id={'confirmation-dialog-title'}>{props.title}</DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText>{props.message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus={true} onClick={handleCancel} color={'secondary'}>
              Cancel
            </Button>
            <Button onClick={handleOk} color={'primary'}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <MenuItem onClick={showConfirmation}>{props.children}</MenuItem>
    </>
  );
};
