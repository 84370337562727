import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import {Config} from '../config';
import {functions} from '../firebase';
import {SortMethod} from '../stores/ui';
import {userStore} from '../stores/user';
import {Gallery, Photo} from '../types/gallery';
import api from './index';

export const getGallery = async (code: string): Promise<Gallery> => {
  const payload = {
    code: code,
    deviceToken: userStore.deviceToken,
  };

  try {
    let response;
    if (Config.apiMethod === 'appengine') {
      response = (
        await api.request<any>({
          method: 'post',
          url: '/getGallery',
          data: {
            data: payload,
          },
        })
      ).data;
    } else {
      response = await functions().httpsCallable('getGallery', {
        timeout: Config.api.timeout,
      })(payload);
    }

    if (response.data.deviceToken) {
      userStore.setDeviceToken(response.data.deviceToken);
    }

    if (response.data.authToken) {
      await userStore.signIn(response.data.authToken);
    }

    return response.data.gallery;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

interface PhotoStreamOptions {
  sorting: SortMethod;
  lastDoc?: any;
  firstDoc?: any;
  tag?: string;
}

// Correctly type the query for the photo stream since the base is a DocumentReference
// but all subsequent additions are queries
type QueryOrReference = firebase.firestore.Query | firebase.firestore.DocumentReference;

export const getPhotoStream = async (
  galleryId: string,
  {sorting, lastDoc, firstDoc, tag}: PhotoStreamOptions,
) => {
  let query: QueryOrReference = firebase.firestore().collection(`photos/${galleryId}/photos`);

  if (tag) {
    query = query.where('tags', 'array-contains', tag);
  }

  query = query.orderBy(sorting === SortMethod.MOST_LIKES ? 'total_likes' : 'posted_at', 'desc');

  if (firstDoc) {
    query = query.endAt(firstDoc);
  }
  if (lastDoc) {
    query = query.startAfter(lastDoc);
  }
  query = query.limit(Config.api.pageSize);

  return (await query.get()) as firebase.firestore.QuerySnapshot<Photo>;
};

export const photoReaction = async (
  galleryId: string,
  photoId: string,
  reaction: string | null,
) => {
  const payload = {
    galleryId: galleryId,
    photoId: photoId,
    reaction: reaction,
  };

  if (Config.apiMethod === 'appengine') {
    await api.request<any>({
      method: 'post',
      url: '/photoReaction',
      data: {
        data: payload,
      },
    });
  } else {
    await functions().httpsCallable('photoReaction', {
      timeout: Config.api.timeout,
    })(payload);
  }
};
