/** Renders text parsing hashtags and making them into links. */
import {Link} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';

const createHashtag = (tag: string, galleryCode: string) => {
  return (
    <Link component={RouterLink} to={`/${galleryCode}/tag/${tag.toLowerCase()}`} key={Math.random()}>
      #{tag}
    </Link>
  );
}

export const renderText = (text: string, galleryCode: string) => {
  let state = 'text';
  let hashtag = '';
  let plainText = '';
  const parts = [];

  for (let i = 0; i < text.length; i++) {
    const char = text.charAt(i);
    if (char === '#') {
      if (state === 'parse_hashtag') {
        state = 'text';
        plainText += '#' + hashtag + char;
        hashtag = '';
      } else {
        state = 'parse_hashtag';
        hashtag = '';
      }
    } else if (char.match(/\s/)) {
      parts.push(plainText);
      plainText = '';
      if (state === 'parse_hashtag' && hashtag) {
        parts.push(createHashtag(hashtag, galleryCode));
        state = 'text';
      }
      if (char === '\n') {
        parts.push(<br/>);
      } else {
        parts.push(char);
      }
    } else if (char.match(/[^\w]/) && state === 'parse_hashtag') {
      // End the parsing of the hash
      if (hashtag) {
        parts.push(createHashtag(hashtag, galleryCode));
      }
      plainText += char;
      hashtag = '';
      state = 'text';
    } else {
      if (state === 'parse_hashtag') {
        hashtag += char;
      } else {
        plainText += char;
      }
    }
  }

  if (plainText) {
    parts.push(plainText);
  }

  if (state === 'parse_hashtag' && hashtag) {
    parts.push(createHashtag(hashtag, galleryCode));
  }

  return parts;
};
