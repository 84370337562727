import {IconButton, Modal, TextField} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import {EmojiData, Picker} from 'emoji-mart';
import firebase from 'firebase/app';
import 'emoji-mart/css/emoji-mart.css';
import './style.css';
import {useRef, useState} from 'react';
import {addPhotoComment} from '../../api/photos';
import {useUser} from '../../context/user';

interface CommentInputProps {
  galleryId: string;
  photoId: string;
  autoFocus?: boolean;
}

export const CommentInput = (props: CommentInputProps) => {
  const [emojiPicker, setEmojiPicker] = useState(false);
  const [commentData, setCommentData] = useState('');
  const [loading, setLoading] = useState(false);
  const textRef = useRef<HTMLInputElement>(null);
  const user = useUser();

  const sendComment = async () => {
    // Sends the comment to the server
    setLoading(true);
    await addPhotoComment(props.photoId, props.galleryId, {
      name: user.name,
      user_id: user.userId,
      comment: commentData,
      posted_at: firebase.firestore.Timestamp.now(),
    });
    setLoading(false);
    setCommentData('');
  };

  const updateCommentData = (e: any) => {
    setCommentData(e.target.value);
  };

  const closeEmojiPicker = () => {
    setEmojiPicker(false);
  };

  const openEmojiPicker = () => {
    setEmojiPicker(true);
  };

  const insertEmoji = (icon: EmojiData) => {
    const emoji = (icon as any).native;
    setCommentData(
      commentData.substr(0, textRef.current!.selectionStart || 0) +
        emoji +
        commentData.substr(textRef.current!.selectionStart || 0, commentData.length - 1),
    );
    setEmojiPicker(false);
  };

  return (
    <>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <IconButton color={'primary'} onClick={openEmojiPicker}>
          <InsertEmoticonIcon />
        </IconButton>
        <TextField
          placeholder={'Write a Comment...'}
          required={true}
          fullWidth={true}
          autoComplete={'comments'}
          autoFocus={!!props.autoFocus}
          multiline={true}
          value={commentData}
          onChange={updateCommentData}
          inputRef={textRef}
          rowsMax={4}
          disabled={loading}
        />
        <IconButton color={'primary'} onClick={sendComment} disabled={loading || commentData.length === 0}>
          <SendIcon />
        </IconButton>
      </div>
      <Modal
        onBackdropClick={closeEmojiPicker}
        open={emojiPicker}
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Picker
          set={'twitter'}
          native={true}
          showPreview={false}
          title={''}
          onSelect={insertEmoji}
        />
      </Modal>
    </>
  );
};
