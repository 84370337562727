import {Typography} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

export const EmptyGallery = () => {
  return (
    <div
      style={{
        height: '60vh',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}>
          <FilterListIcon color={'action'} fontSize={'large'} />
          <Typography>There are no photos here yet</Typography>
    </div>
  );
};
