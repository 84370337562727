import {CircularProgress} from '@material-ui/core';

export const LoadingIndicator = (props: {tiny?: boolean}) => {
  if (props.tiny) {
    return (
      <div style={{flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div style={{height: '100vh', flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
      <CircularProgress />
    </div>
  )
}
