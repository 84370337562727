import {
  AppBar,
  Container,
  createStyles,
  Divider,
  IconButton,
  makeStyles,
  Modal,
  Paper,
  Theme,
  Toolbar,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import firebase from 'firebase/app';
import {useEffect, useRef, useState} from 'react';
import {useQueryClient} from 'react-query';
import {useHistory, useLocation, useParams} from 'react-router';
import {watchPhotoComments} from '../../api/photos';
import {Comment} from '../../components/Comment';
import {CommentInput} from '../../components/CommentInput';
import {PhotoCard, PhotoCardPlaceholder} from '../../components/PhotoCard';
import {Photo, PhotoComment} from '../../types/gallery';

export const PhotoView = (props: {galleryId: string}) => {
  const history = useHistory();
  const location = useLocation<{scrollTo?: 'comments'}>();
  const queryClient = useQueryClient();
  const {photoId, code} = useParams<{photoId: string; code: string}>();
  const [loaded, setLoaded] = useState(false);
  const [photo, setPhoto] = useState<Photo | undefined>(undefined);
  const docRef = useRef(firebase.firestore().doc(`/photos/${props.galleryId}/photos/${photoId}`));
  const commentRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();

  const goBack = () => {
    history.push(`/${code}`);
  };

  const scrollToComment = () => {
    commentRef.current?.scrollTo({behavior: 'smooth'});
  };

  const clearCache = () => {
    queryClient.clear();
    goBack();
  };

  useEffect(() => {
    if (location.state?.scrollTo === 'comments') {
      scrollToComment();
    }
  }, [commentRef.current]);

  useEffect(() => {
    // Watch
    docRef.current = firebase.firestore().doc(`/photos/${props.galleryId}/photos/${photoId}`);
    setLoaded(false);
    const watcher = docRef.current.onSnapshot(
      (result) => {
        if (!result.exists) {
          // TODO: Show not found message
          goBack();
        }
        setLoaded(true);
        setPhoto(result.data() as Photo);
      },
      (error) => {
        if (error.code === 'not-found') {
          goBack();
        }
      },
    );

    return () => {
      watcher();
    };
  }, [props.galleryId, photoId]);

  return (
    <Modal open={true} className={classes.modalRoot}>
      <Paper className={classes.paper}>
        <AppBar>
          <Toolbar>
            <IconButton color={'inherit'} onClick={goBack}>
              <ArrowBackIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Toolbar />
        <Container maxWidth={'lg'}>
          {!loaded || !photo ? (
            <PhotoCardPlaceholder />
          ) : (
            <>
              <PhotoCard
                galleryId={props.galleryId}
                docRef={docRef.current}
                id={photoId}
                {...photo}
                onComment={scrollToComment}
                onDeleted={clearCache}
              />
              <Divider />
              <div className={classes.root}>
                <PhotoComments photoId={photoId} galleryId={props.galleryId} />
              </div>
            </>
          )}
        </Container>
        <Toolbar />
        <Toolbar />
        {!loaded || !photo ? null : (
          <Paper elevation={5} className={classes.commentInput}>
            <CommentInput
              galleryId={props.galleryId}
              photoId={photoId}
              autoFocus={location.state?.scrollTo === 'comments'}
            />
          </Paper>
        )}
      </Paper>
    </Modal>
  );
};

const PhotoComments = ({photoId, galleryId}: {photoId: string; galleryId: string}) => {
  const [comments, setComments] = useState<
    firebase.firestore.QueryDocumentSnapshot<PhotoComment>[]
  >([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const watcher = watchPhotoComments(
      photoId,
      galleryId,
      (result) => {
        setComments(result);
        setError('');
      },
      (error) => {
        console.log(error.message);
        setError('Could not load comments');
      },
    );

    return () => {
      watcher();
    };
  }, [galleryId, photoId]);

  if (error) {
    return <div>Could not load comments</div>;
  }

  if (!comments) {
    return null;
  }

  return (
    <>
      {comments.map((comment) => (
        <Comment {...comment.data()} key={comment.id} />
      ))}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalRoot: {
      height: '100vh',
      display: 'flex',
    },

    paper: {
      flex: 1,
      flexGrow: 1,
      minHeight: '100vh',
      overflowY: 'auto',
    },

    root: {
      margin: theme.spacing(0),
    },

    commentInput: {
      marginTop: '1rem',
      padding: '0.5rem',
      bottom: 0,
      left: 0,
      width: '100%',
      position: 'fixed',
    },
  }),
);
